import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {makeStyles} from "@material-ui/core";
import logoIcon from './../assets/images/press/hero-logo-icon.png';
import logoFilledFontLower from './../assets/images/press/logo_filled_font_lower.png';
import logoWithFont from './../assets/images/press/logo_with_font.png';
import logoOutlineNoFont from './../assets/images/press/logo_outline_no_font.png';

const customComponentStyles = (theme) => (
    {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "126px",
            color: "#212121",
            fontWeight: "300",
            textAlign: "center",
            minHeight: "100vh",
            justifyContent: "center"
        },
        pageTitle: {
            fontSize: "36px",
            lineHeight: "54px",
            letterSpacing: "0.205em",
            fontWeight: "300",
            marginBottom: "60px"
        },
        pressContent: {
            textAlign: "left"
        },
        pressSection: {
            display: "flex",
            flexDirection: "column",
            maxWidth: "80%",
            margin: "0 auto 40px"
        },
        headline: {
            fontSize: "24px",
            lineHeight: "17px",
            fontWeight: "300",
            marginBottom: "18px"
        },
        logosContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        },
        logo: {
            maxWidth: "200px",
            marginBottom: "40px"
        }
    }
);

const useStyles = makeStyles((theme) => ({
    ...customComponentStyles(theme)
}));

function Imprint() {
    const classes = useStyles();

  return (
      <div className={classes.container}>
          <div className={classes.pageTitle}>Press</div>
          <div className={classes.pressContent}>
              <div className={classes.pressSection}>
                  <h1 className={classes.headline}>Wording</h1>
                  <p>
                      For the wording please always write <b>HEROcoin</b> with "HERO" in capital letters and "coin" uncapitalised.
                      The ticker of HEROcoin is "PLAY". If you use the ticker name, then always in capital letters.
                      All the projects of HEROcoin in total are called the "HERO Ecosystem". A single project is called "HERO ecosystem project".
                  </p>
              </div>
              <div className={classes.pressSection}>
                  <h1 className={classes.headline}>Logos</h1>
                  <p>
                      Please acknowledge, that changing the color or shape of the logo is not permitted.
                  </p>
                  <div className={classes.logosContainer}>
                      <img alt={"HEROcoin Logo Icon"} src={logoIcon} className={classes.logo}/>
                      <img alt={"HEROcoin Logo with font lower"} src={logoFilledFontLower} className={classes.logo}/>
                      <img alt={"HEROcoin Logo with font"} src={logoWithFont} className={classes.logo}/>
                      <img alt={"HEROcoin Logo outlined"} src={logoOutlineNoFont} className={classes.logo}/>
                  </div>

              </div>
          </div>
      </div>
  )
}

export default Imprint;
