const heroButtonOutlined = ((theme) => ({
    heroButtonOutlined: {
        background: "#E1F5FE !important",
        color: "#3EACD4 !important",
        height: "48px",
        width: "252px",
        boxShadow: "inset 0px 0px 20px rgba(255, 255, 255, 0.53) !important",
        fontFamily: "'Poppins', serif !important",
        borderRadius: "12px !important",
        fontWeight: "600 !important",
        fontSize: "16px !important",
        lineHeight: "24px !important",
        padding: "12px 36px !important",
        textTransform: "none !important",

        //tablet
        [theme.breakpoints.up('sm')]: {
            width: "100% !important",
            maxWidth: "225px !important",
            minWidth: "155px !important",
        },

        //desktop
        [theme.breakpoints.up('lg')]: {
            width: "188px !important"
        },
    }
}));

export default heroButtonOutlined;