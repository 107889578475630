import React from "react";
import {
    AppBar,
    Toolbar,
    CssBaseline,
    makeStyles,
} from "@material-ui/core";
import logo from './../assets/images/logo.png';
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter, faTelegram, faReddit, faBitcoin, faFacebook} from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#ffffff",
        boxShadow: "none"
    },
    toolBar: {
        display: "flex",
        justifyContent: "space-between",

        //tablet
        [theme.breakpoints.up('sm')]: {
            justifyContent: "space-around"
        },

        //desktop
        [theme.breakpoints.up('lg')]: {
            padding: "0 194px !important"
        }
    },
    navLinks: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "180px",

        //tablet and up
        [theme.breakpoints.up('sm')]: {
            width: "180px",
            maxWidth: "unset"
        },

    },
    logo:{
        maxHeight: "48px"
    },
    navText: {
        color: "#F2F2F2",
        textDecoration: "none",
        flexGrow: "1",
        maxWidth: "125px",
        paddingTop: "12px",

        //desktop
        [theme.breakpoints.up('lg')]: {
            maxWidth: "unset",
            paddingTop: "0",
        },
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(20),
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
    socialMediaMiniButton:{
        minWidth: "36px !important",
        width: "36px !important",
        height: "36px !important",
        border: "none !important",
        padding: "0 !important",

        //tablet and up
        [theme.breakpoints.up('sm')]: {
            minWidth: "42px !important",
            width: "42px !important",
            height: "42px !important",
        },
    },
    socialMediaMiniButtonIcon: {
        width: "24px !important",
        height: "24px !important",
        color: "#828282",
        "&:hover": {
            color: "#72E8F1",
        },

        //tablet and up
        [theme.breakpoints.up('sm')]: {
            width: "28px !important",
            height: "28px !important",
        },
    },
}));

function Navbar() {
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <CssBaseline/>
            <Toolbar className={classes.toolBar}>
                <a href={"/"} className={classes.navText}><img src={logo} alt="logo" className={classes.logo}/></a>
                <div className={classes.navLinks}>
                    {/*<IconButton
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>*/}
                    <Button title={"Telegram"} href={"https://t.me/herocoinio"} target={"_blank"} rel={"noreferrer"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faTelegram} size={"lg"} /></Button>
                    <Button title={"Twitter"} href={"https://twitter.com/HEROcoinPLAY"} target={"_blank"} rel={"noreferrer"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faTwitter} size={"lg"} /></Button>
                    <Button title={"Reddit"} href={"https://www.reddit.com/r/HEROcoin/"} target={"_blank"} rel={"noreferrer"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faReddit} size={"lg"} /></Button>
                    <Button title={"Bitcointalk"} href={"https://bitcointalk.org/index.php?topic=2116864.msg21169697#msg21169697"} target={"_blank"} rel={"noreferrer"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faBitcoin} size={"lg"} /></Button>
                    <Button title={"Facebook"} href={"https://www.facebook.com/HEROcoinio/"} target={"_blank"} rel={"noreferrer"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faFacebook} size={"lg"} /></Button>

                    {/*<Link to="/" className={classes.link}>
                        How it works
                    </Link>
                    <Link to="/about" className={classes.link}>
                        Take the adventure
                    </Link>
                    <Link to="/contact" className={classes.link}>
                        Alpha Passes
                    </Link>
                    <Link to="/faq" className={classes.link}>
                        Roadmap
                    </Link>*/}
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;