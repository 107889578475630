import React from "react";
import {
    makeStyles
} from "@material-ui/core";
import {Button} from "@mui/material";
import logo from './../assets/images/logo.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBitcoin, faFacebook, faLinkedin, faReddit} from "@fortawesome/free-brands-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";


const customComponentStyles = (theme) => (
    {
        container: {
            display: "flex",
            flexGrow: "1",
            flexDirection: "column",
            alignItems: "left",
            color: "#616161",
            fontWeight: "300",
            textAlign: "center",
            background: "#F5F5F5",
            padding: "30px 20px",
            marginTop: "90px",

            //tablet
            [theme.breakpoints.up('sm')]: {
                marginTop: "114px",
                padding: "30px 42px",
                minHeight: "245px",
                justifyContent: "center",
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                marginTop: "126px",
                padding: "30px 42px",
                minHeight: "245px",
                justifyContent: "center",
            },
        },
        footerContainer: {
            //tablet
            [theme.breakpoints.up('sm')]: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "600px",
                alignSelf: "center",
                justifyContent: "space-between"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                maxWidth: "950px",
                width: "100%",
                alignSelf: "center"
            },
        },
        brandContainer: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginBottom: "30px",

            //tablet
            [theme.breakpoints.up('sm')]: {
                flexGrow: 1,
                justifyContent: "center"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                marginBottom: "0",
                maxWidth: "200px",
                alignItems: "flex-start",
            },
        },
        logo:{
            height: "66px",
        },
        brandName: {
            flexGrow: "1",
            textTransform: "uppercase",
            marginLeft: "24px",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "21px",
            letterSpacing: "0.05em",
            color: "#616161",
            textAlign: "left",

            //tablet
            [theme.breakpoints.up('sm')]: {
                flexGrow: "unset"
            },
        },
        socialMediaContainer: {
            fontWeight: "300",
            fontSize: "12px",
            lineHeight: "28px",
            letterSpacing: "0.05em",
            color: "#616161",
            textAlign: "left",
            width: "100%",
            marginTop: "30px",

            //tablet
            [theme.breakpoints.up('sm')]: {
                marginTop: "0",
                width: "150px"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                marginTop: "0",
                width: "unset",
                minWidth: "135px",
                maxWidth: "135px",
            },
        },
        socialMediaIcons:{
            display: "flex",
            marginTop: "7px",
            justifyContent: "space-between",
            flexWrap: "wrap"
        },
        socialMediaMiniButton:{
            minWidth: "36px !important",
            maxWidth: "36px !important",
            height: "36px !important",
            borderRadius: "12px !important",
            border: "0.5px solid #616161 !important",
            flex: "1 0 30% !important",
            marginBottom: "8px !important"
        },
        socialMediaMiniButtonIcon: {
            color: "#616161"
        },
        linksContainer:{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",

            //tablet
            [theme.breakpoints.up('sm')]: {
                maxWidth: "320px"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                width: "unset",
                //minWidth: "400px"
            },
        },
        linksContainerRow:{
            display: "flex",
            flexDirection: "column"
        },
        footerLink:{
            textDecoration: "none",
            color: "#616161",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "28px",
            letterSpacing: "0.05em",
            textAlign: "left",
        }
    }
);

const useStyles = makeStyles((theme) => ({
    ...customComponentStyles(theme)
}));


function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.footerContainer}>
                <div className={classes.brandContainer}>
                    <img src={logo} alt="logo" className={classes.logo}/>
                </div>
                <div className={classes.linksContainer}>
                    <div className={classes.linksContainerRow}>
                        <a href={"/imprint"} className={classes.footerLink}>Imprint</a>
                        <a href={"/press"} className={classes.footerLink}>Press</a>
                        <a href={"https://s3-cdn.herocoin.io/HERO_Whitepaper.pdf"} className={classes.footerLink}>HEROcoin Litepaper</a>
                    </div>
                    {/*<div className={classes.linksContainerRow}>
                        <a href={""} target={"_blank"} className={classes.footerLink}>Terms of Use</a>
                        <a href={""} target={"_blank"} className={classes.footerLink}>Team</a>
                    </div>
                    <div className={classes.linksContainerRow}>
                        <a href={""} target={"_blank"} className={classes.footerLink}>Privacy Policy</a>
                        <a href={""} target={"_blank"} className={classes.footerLink}>Gitbook</a>
                    </div>*/}
                </div>
                <div className={classes.socialMediaContainer}>
                    <div>Social Media</div>
                    <div className={classes.socialMediaIcons}>
                        <Button title={"Twitter"} href={"https://twitter.com/HEROcoinPLAY"} target={"_blank"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faTwitter} size={"lg"} /></Button>
                        <Button title={"Telegram"} href={"https://t.me/herocoinio"} target={"_blank"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faTelegram} size={"lg"} /></Button>
                        <Button title={"Reddit"} href={"https://www.reddit.com/r/HEROcoin/"} target={"_blank"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faReddit} size={"lg"} /></Button>
                        <Button title={"Bitcointalk"} href={"https://bitcointalk.org/index.php?topic=2116864.msg21169697#msg21169697"} target={"_blank"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faBitcoin} size={"lg"} /></Button>
                        <Button title={"Facebook"} href={"https://www.facebook.com/HEROcoinio/"} target={"_blank"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faFacebook} size={"lg"} /></Button>
                        <Button title={"LinkedIn"} href={"https://www.linkedin.com/company/herocoin"} target={"_blank"} className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faLinkedin} size={"lg"} /></Button>
                        {/*<Button className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faTwitch} size={"lg"} /></Button>
                        <Button className={classes.socialMediaMiniButton} variant="outlined"><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faTelegram} size={"lg"} /></Button>*/}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Footer;