import React, { Suspense } from "react";
import Hero from "./../components/Hero";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BulletPoints = React.lazy(() => import("../components/BulletPoints"));
const Ecosystem = React.lazy(() => import("../components/Ecosystem"));
const Listing = React.lazy(() => import("../components/Listing"));
const Roadmap = React.lazy(() => import("../components/Roadmap"));
const AsSeenOn = React.lazy(() => import("../components/AsSeenOn"));
const Team = React.lazy(() => import("../components/Team"));

function Home() {
  return (
      <div>
          <Hero />
          <Suspense fallback={<div>Loading Bullet Points...</div>}>
              <BulletPoints />
          </Suspense>
          <Suspense fallback={<div>Loading Ecosystem...</div>}>
              <Ecosystem />
          </Suspense>
          <Suspense fallback={<div>Loading Listing...</div>}>
              <Listing />
          </Suspense>
          <Suspense fallback={<div>Loading Roadmap...</div>}>
              <Roadmap />
          </Suspense>
          <Suspense fallback={<div>Loading As Seen On...</div>}>
              <AsSeenOn />
          </Suspense>
          <Suspense fallback={<div>Loading Team...</div>}>
              <Team />
          </Suspense>
      </div>
  )
}

export default Home;
