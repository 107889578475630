import React from "react";
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Imprint from "./pages/Imprint";
import Press from "./pages/Press";
import Layout from "./layout/Layout";

//const Contact = React.lazy(() => import("./Components/Contact/Contact"));

function App() {
  return (
      <Router>
          <Layout>
              <Routes>
                  <Route path='/' element={<Home />}/>
                  <Route path="/imprint" element={<Imprint />} />
                  <Route path="/press" element={<Press />} />
              </Routes>
          </Layout>
      </Router>
  );
}

export default App;
