import React from "react";
import {
    makeStyles,
} from "@material-ui/core";
import projectMetaNanos from './../assets/images/projects/project_metananos.png';
import projectPlayersClub from './../assets/images/projects/project_playersclub.png';
import projectHerosphere from './../assets/images/projects/project_herosphere.png';
import projectSkiChallenge from './../assets/images/projects/project_skichallenge.png';
import {Button} from "@mui/material";
import heroButtonOutlined from "../styles/HeroButtonOutlined";
import heroButtonFilled from "../styles/HeroButtonFilled";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-scroll";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";

const customComponentStyles = (theme) => (
    {
        heroContainer: {
            //mobile
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center",
            marginTop: "60px",
            position: "relative",

            //tablet
            [theme.breakpoints.up('sm')]: {
                marginTop: "126px",
                flexDirection: "row-reverse",
                justifyContent: "space-evenly",
                alignItems: "flex-start",
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                justifyContent: "center",
            },
        },
        heroContentContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "95%",

            //tablet
            [theme.breakpoints.up('sm')]: {
                width: "100%",
                alignItems: "flex-start",
                marginRight: "0",
                justifyContent: "space-between",
                maxWidth: "470px",
                minWidth: "350px",
                padding: "0 18px"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                width: "100%",
                alignItems: "flex-start",
                marginRight: "50px",
                height: "415px",
                justifyContent: "space-between",
                maxWidth: "470px",
                padding: 0
            }
        },
        heroTextContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginBottom: "40px",
            alignItems: "center",
        },
        heroText: {
            fontWeight: "600",
            color: "#212121",
            width: "100%",
            fontSize: "30px",
            lineHeight: "40px",
            textAlign: "center",

            //tablet
            [theme.breakpoints.up('sm')]: {
                textAlign: "left",
                fontSize: "32px",
                lineHeight: "28px",
                maxWidth: "500px",
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                textAlign: "left",
                fontSize: "48px",
                lineHeight: "53px",
                maxWidth: "500px",
            },
        },
        heroTextEnlighted: {
            fontWeight: "bold",
            color: "#212121",
            fontSize: "34px",
            lineHeight: "40px",
            textAlign: "center",

            //tablet
            [theme.breakpoints.up('sm')]: {
                textAlign: "left",
                fontSize: "32px",
                lineHeight: "28px",

            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                textAlign: "left",
                fontSize: "48px",
                lineHeight: "53px",

            }
        },
        heroSubTitle: {
            color: "#757575",
            marginTop: "18px",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "normal",
            width: "90%",
            textAlign: "center",

            //tablet
            [theme.breakpoints.up('sm')]: {
                width: "100%",
                textAlign: "left",
                maxWidth: "500px"
            },
        },
        heroButtonContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            marginBottom: "60px",

            //tablet
            [theme.breakpoints.up('sm')]: {
                marginBottom: "0",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "12px"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                flexDirection: "row",
                justifyContent: "space-between",
                maxWidth: "388px"
            },
        },
        discoverLink: {
            //tablet
            [theme.breakpoints.up('sm')]: {
                width: "100%"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                width: "unset"
            },
        },
        socialMediaMiniButtonIcon: {
            marginRight: "8px"
        },
        sliderArrowLeft:{
            position: "absolute",
            zIndex: "1",
            left: "50px",
            bottom: "0px",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            background: "#e0e0e0",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 15px 16px -12px rgba(0, 0, 0, 0.25)",
            borderColor: "unset",
            borderWidth: "0px",

            //tablet
            [theme.breakpoints.up('sm')]: {
                left: "10px",
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                left: "50px",
            },
        },
        sliderArrowRight:{
            position: "absolute",
            zIndex: "1",
            right: "50px",
            bottom: "0px",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            background: "#e0e0e0",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 15px 16px -12px rgba(0, 0, 0, 0.25)",
            borderColor: "unset",
            borderWidth: "0px",

            //tablet
            [theme.breakpoints.up('sm')]: {
                right: "10px",
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                right: "50px",
            },
        },
        sliderArrowIconLeft:{
            color: "#FFFFFF",
            marginRight: "2px"
        },
        sliderArrowIconRight:{
            color: "#FFFFFF",
            marginLeft: "2px"
        },
        milestoneTopContainer:{
            display: "flex",
            alignItems: "flex-start"
        },
        projectImage: {
            width: "253px",
            margin: "0 auto",

            //tablet
            [theme.breakpoints.up('sm')]: {
                width: "100%",
                maxWidth: "360px"
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                width: "360px",
            },
        },
        sliderContainer: {
            width: "100%",
            height: "365px",
            position: "relative",

            //tablet
            [theme.breakpoints.up('sm')]: {
                maxWidth: "240px",
                height: "390px",
            },

            //desktop
            [theme.breakpoints.up('lg')]: {
                maxWidth: "387px",
                height: "415px",
            },
        },
        projectDescription: {
            color: "#757575",
            fontSize: "14px",
            lineHeight: "21px",
            maxWidth: "90%",
            margin: "0 auto 18px",
            textAlign: "center"
        }
    }
);

const useStyles = makeStyles((theme) => ({
    ...customComponentStyles(theme),
    ...heroButtonFilled(theme),
    ...heroButtonOutlined(theme)
}));


function Hero() {
    const classes = useStyles();
    const slider = React.useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <div className={classes.heroContainer}>
            <div className={classes.sliderContainer}>
                <Slider ref={slider} {...settings}>
                    <div className={classes.milestoneTopContainer}>
                        <img alt={"project meta nanos"} className={classes.projectImage} src={projectMetaNanos} />
                        <p className={classes.projectDescription}>The next level 3D play-to-earn metaverse. This project will use PLAY for early access, give discounts to PLAY holders and integrate PLAY into the metaverse.
                            <br/>
                            <a href={"https://www.metananos.com"} target={"_blank"} rel={"noreferrer"}>www.metananos.com</a>
                        </p>
                    </div>
                    <div className={classes.milestoneTopContainer}>
                        <img alt={"project players club"} className={classes.projectImage} src={projectPlayersClub} />
                        <p className={classes.projectDescription}>The Players Club aims to become the biggest and most advanced loyalty system in the iGaming industry. It will launch in early Q1 2022. PLAY will be used for staking, exclusive deals and much more.</p>
                    </div>
                    <div className={classes.milestoneTopContainer}>
                        <img alt={"project herosphere"} className={classes.projectImage} src={projectHerosphere} />
                        <p className={classes.projectDescription}>Herosphere is an esports fantasy betting platform. It launched 2018 as a beta version and was used by more than 300k users. The reimplementation kick-off is in early Q1 2022. PLAY will be used for staking and benefits.</p>
                    </div>
                    <div className={classes.milestoneTopContainer}>
                        <img alt={"secret project"} className={classes.projectImage} src={projectSkiChallenge} />
                        <p className={classes.projectDescription}>The famous game "Ski Challenge" is back. The game revived in Q4 2022. HEROcoin is already visible in game on banner advertisement areas. Deeper PLAY integration will follow soon.</p>
                    </div>
                </Slider>
                <button className={classes.sliderArrowLeft} onClick={() => slider?.current?.slickPrev()}><FontAwesomeIcon className={classes.sliderArrowIconLeft} icon={faChevronLeft} size={"lg"} /></button>
                <button className={classes.sliderArrowRight} onClick={() => slider?.current?.slickNext()}><FontAwesomeIcon className={classes.sliderArrowIconRight} icon={faChevronRight} size={"lg"} /></button>
            </div>


            <div className={classes.heroContentContainer}>
                <div className={classes.heroTextContainer}>
                    <div className={classes.heroText}>
                        <h1 className={classes.heroTextEnlighted}>HEROcoin (PLAY)</h1> <h1>The entertainment ecosystem token</h1>
                    </div>
                    <h2 className={classes.heroSubTitle}>
                        The HERO ecosystem combines projects of different industries like iGaming, esports and NFTs and establishes its own Metaverse.
                        Each project leverages HEROcoin (PLAY) in different ways. HEROcoin's success comes with the success of its ecosystem projects.
                        <br/><br/>Join all of them to get the most out of your PLAY!
                    </h2>
                </div>

                <div className={classes.heroButtonContainer}>
                    <Button href={"https://t.me/herocoinio"} target={"_blank"} rel={"noreferrer"} variant="contained" className={classes.heroButtonFilled}><FontAwesomeIcon className={classes.socialMediaMiniButtonIcon} icon={faTelegram} size={"lg"} />Telegram</Button>
                    <Link to={"ecosystem"} spy={false} smooth={true} className={classes.discoverLink}><Button variant="contained" className={classes.heroButtonOutlined}>Discover</Button></Link>
                </div>
            </div>

        </div>
    );
}

export default Hero;