const heroButtonFilled = ((theme) => ({
    heroButtonFilled: {
        background: "linear-gradient(273.09deg, #72E8F1 29.85%, #3EACD4 133.73%) !important",
        height: "48px",
        width: "252px",
        boxShadow: "inset 0px 0px 20px rgba(255, 255, 255, 0.53) !important",
        fontFamily: "'Poppins', serif !important",
        borderRadius: "12px !important",
        fontWeight: "600 !important",
        fontSize: "16px !important",
        lineHeight: "24px !important",
        padding: "12px 36px !important",
        textTransform: "none !important",
        marginBottom: "12px !important",

        //tablet
        [theme.breakpoints.up('sm')]: {
            width: "100% !important",
            maxWidth: "225px !important",
            minWidth: "155px !important",
            marginBottom: "0 !important",
        },

        //desktop
        [theme.breakpoints.up('lg')]: {
            width: "188px !important",
            marginBottom: "0 !important",
        },
    }
}));

export default heroButtonFilled;