import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CookieConsent from "react-cookie-consent";

function Layout(props) {

  return (
      <div>
          <Navbar />

          {props.children}

          <Footer />
          <CookieConsent
              location="bottom"
              buttonText="I understood"
              style={{ background: "#1F1F1F", color:"#F2F2F2" }}
              buttonStyle={{ background: "linear-gradient(273.09deg, #72E8F1 29.85%, #3EACD4 133.73%)",
                  boxShadow: "0px 15px 16px -12px #8F00FF80",
                  fontFamily: "'Poppins', serif",
                  borderRadius: "12px",
                  fontWeight: "600",
                  color: "#F2F2F2",
                  minWidth: "125px",
                  fontSize: "14px" }}>
              We use cookies! This allows us to improve our contents and offer you an optimal result on our website.
          </CookieConsent>
      </div>
  )
}

export default Layout;
