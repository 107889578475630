import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {makeStyles} from "@material-ui/core";

const customComponentStyles = (theme) => (
    {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "126px",
            color: "#212121",
            fontWeight: "300",
            textAlign: "center",
            minHeight: "100vh"
        },
        pageTitle: {
            fontSize: "36px",
            lineHeight: "54px",
            letterSpacing: "0.205em",
            fontWeight: "300",
            marginBottom: "60px"
        },
        imprintText: {
            textAlign: "left"
        },
        companyName: {
            marginBottom: "12px",
            fontSize: "16px",
            fontWeight: "600"
        },
        email: {
            marginTop: "12px"
        }
    }
);

const useStyles = makeStyles((theme) => ({
    ...customComponentStyles(theme)
}));

function Imprint() {
    const classes = useStyles();

  return (
      <div className={classes.container}>
          <div className={classes.pageTitle}>Imprint</div>
          <div className={classes.imprintText}>
              <div className={classes.companyName}>Greentube GmbH</div>

              <div>Wiedner Hauptstrasse 94</div>
              <div>1050 Vienna</div>
              <div>Austria</div>

              <div className={classes.email}>Email: info@herocoin.io</div>
              <div className={classes.email}>VAT: ATU50066002</div>
              <div className={classes.email}>Company Registration No: 197003k</div>
              <div className={classes.email}>Commercial Register: Handelsgericht Wien</div>
              <div className={classes.email}>Affiliation: Wirtschaftskammer Wien</div>
          </div>
      </div>
  )
}

export default Imprint;
